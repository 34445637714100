import {
  Box,
  Button,
  Center,
  Checkbox,
  Dialog,
  DialogActions,
  DialogHeader,
  Icon,
  IconOverlap,
  Image,
  Input,
  InputWrapper,
  PhoneMockup,
  PhoneMockupBodyLayout,
  PhoneMockupLayout,
  Text,
  TextLink,
  useToast,
  WidgetHeader,
  WidgetHeaderContent,
  WidgetHeaderTicker,
  WidgetStatus,
} from '@frontend/ui-library';
import { SubmitHandler, useForm } from 'react-hook-form';

import { chainNameBySymbol, createAssetSrc } from '@frontend/utils';

import styles from './RefundRequest.module.css';
import useRefundRequest from './hooks/useRefundRequest/useRefundRequest';
import { Currency, useAcceptRefundMutation } from '../../graphql/generated';
import { ApolloError } from '@apollo/client';
import { GET_REFUND_STATUS } from '../../graphql/queries';
import { useEffect, useState } from 'react';
import useAutoRefetchProposal from './hooks/useAutoCreateProposal/useAutoCreateProposal';
import { useCreateProposalMutation } from './hooks/useCreateProposalMutation';
import { links } from '../../utils/links';
import { ReactComponent as ExternalLinkSvg } from '@frontend/icons/external-link.svg';

const FORM_ID = 'refund-form';

export default function RefundRequest(): JSX.Element {
  const {
    data: {
      fiatAmount,
      fiatCurrency,
      exchangeRate,
      cryptoAmount,
      cryptoCurrency,
      shouldShowUSDTWarning,
      network,
      expiresAt,
      id,
    },
    hasProposal,
    loading,
  } = useRefundRequest();

  const { createProposal } = useCreateProposalMutation(id || undefined);

  useAutoRefetchProposal({
    hasProposal,
    loading,
    expiresAt,
    createProposal,
  });

  const [submitting, setSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const cryptoCurrencyAssetSrc = createAssetSrc(
    cryptoCurrency || '',
    'tickers'
  );
  const networkAssetSrc = createAssetSrc(network || '', 'networks');

  return (
    <PhoneMockupLayout>
      <PhoneMockup
        header={
          <WidgetHeader>
            <WidgetHeaderContent
              title="Your Refund details"
              rightSlot={
                exchangeRate &&
                fiatCurrency &&
                cryptoCurrency && (
                  <WidgetHeaderTicker
                    mobileTooltipPlacement="bottom-end"
                    ticker={
                      <Image
                        src={cryptoCurrencyAssetSrc}
                        alt={cryptoCurrency}
                      />
                    }
                    value={`${Number(exchangeRate.rate as string).toFixed(
                      2
                    )} ${fiatCurrency.toUpperCase()}`}
                    tooltipContent={`${cryptoCurrency.toUpperCase()} to ${fiatCurrency.toUpperCase()} conversion rate`}
                  />
                )
              }
            />
          </WidgetHeader>
        }
      >
        <PhoneMockupBodyLayout
          bodyCentered
          body={
            <>
              {cryptoCurrency && network && (
                <RefundDetails
                  fiatAmount={fiatAmount}
                  fiatCurrency={fiatCurrency || undefined}
                  cryptoAmount={cryptoAmount}
                  cryptoCurrency={cryptoCurrency as Currency}
                  network={network}
                />
              )}
              {cryptoCurrency && network && (
                <Form
                  cryptoCurrency={cryptoCurrency}
                  shouldShowUSDTWarning={shouldShowUSDTWarning}
                  cryptoCurrencyAssetSrc={cryptoCurrencyAssetSrc}
                  network={network}
                  networkAssetSrc={networkAssetSrc}
                  onLoadingChange={(loading) => setSubmitting(loading)}
                  onValidityChange={(valid) => setIsFormValid(valid)}
                />
              )}
            </>
          }
          footer={
            <Button
              type="submit"
              form={FORM_ID}
              fullWidth
              variant="primary"
              disabled={!isFormValid}
              loading={submitting || loading}
            >
              Get refund
            </Button>
          }
        />
      </PhoneMockup>
    </PhoneMockupLayout>
  );
}

interface RefundDetailsProps {
  fiatAmount?: string;
  fiatCurrency?: Currency;
  cryptoAmount: string;
  cryptoCurrency: Currency;
  network: string;
}

function RefundDetails({
  fiatAmount,
  fiatCurrency,
  cryptoAmount,
  cryptoCurrency,
  network,
}: RefundDetailsProps): JSX.Element {
  const title =
    fiatAmount && fiatCurrency
      ? `Refunding ${fiatAmount} ${fiatCurrency.toUpperCase()}`
      : `Refunding ${cryptoAmount} ${cryptoCurrency.toUpperCase()}`;

  const subTitle =
    fiatAmount && fiatCurrency
      ? `${cryptoAmount} ${cryptoCurrency.toUpperCase()}`
      : '';

  return (
    <Box className={styles['refund-details']}>
      <WidgetStatus emoji="💸" title={title} />
      {subTitle && (
        <Text weight="600" size="base" mt="4" color="neutral-500">
          {subTitle}
        </Text>
      )}
      <Text weight="400" size="small" mt="8" color="neutral-600">
        This refund will be executed on the{' '}
        <Text size="inherit" weight="600" color="inherit">
          {chainNameBySymbol[network]} network
        </Text>
      </Text>
    </Box>
  );
}

type Inputs = {
  address: string;
  consent: false;
};

interface Form {
  cryptoCurrency: string;
  shouldShowUSDTWarning: boolean;
  cryptoCurrencyAssetSrc: string;
  network: string;
  networkAssetSrc: string;
  onLoadingChange?: (loading: boolean) => void;
  onValidityChange?: (valid: boolean) => void;
}

function Form({
  cryptoCurrency,
  cryptoCurrencyAssetSrc,
  shouldShowUSDTWarning,
  network,
  networkAssetSrc,
  onLoadingChange,
  onValidityChange,
}: Form) {
  const toast = useToast();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      address: '',
    },
  });

  const handleError = (error: ApolloError) => {
    const invalidAddressError = error.graphQLErrors.find((graphQLError) =>
      /(Invalid address|xMoney payment address|Segwit)/.test(
        graphQLError.message
      )
    );

    if (invalidAddressError) {
      return setError('address', {
        message: invalidAddressError.message || 'Something went wrong',
      });
    }

    toast.open({
      title: 'Error',
      status: 'error',
      message: 'Something went wrong.',
      autoHideDuration: 5000,
    });
  };

  const [acceptRefund, { loading: acceptRefundLoading }] =
    useAcceptRefundMutation({
      onError: handleError,
      refetchQueries: [GET_REFUND_STATUS],
    });

  useEffect(() => {
    onValidityChange?.(isValid);
  }, [isValid, onValidityChange]);

  useEffect(() => {
    onLoadingChange?.(acceptRefundLoading);
  }, [acceptRefundLoading, onLoadingChange]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    acceptRefund({
      variables: {
        address: data.address,
      },
    });
  };

  const inputPlaceholder = `Your ${chainNameBySymbol[network]} network address`;

  const [showInformationDialog, setShowInformationDialog] = useState(false);

  const closeInformationDialog = () => {
    setShowInformationDialog(false);
  };

  const openToast = () => {
    toast.open({
      title: 'Important information',
      status: 'info',
      message: <USDTMessage />,
    });
  };

  useEffect(() => {
    if (shouldShowUSDTWarning) {
      openToast();
      setShowInformationDialog(true);
    }
  }, [shouldShowUSDTWarning]);

  return (
    <>
      {showInformationDialog && (
        <InformationDialog onClose={closeInformationDialog} />
      )}
      <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
        <InputWrapper error={errors.address?.message}>
          <Input
            mt="16"
            placeholder={inputPlaceholder}
            {...register('address', {
              required: 'Address is required',
            })}
            error={!!errors?.address}
            leftIcon={
              <IconOverlap
                size={32}
                mainIcon={
                  <Image
                    src={cryptoCurrencyAssetSrc}
                    alt={cryptoCurrency || ''}
                  />
                }
                overlapIcon={
                  <Image src={networkAssetSrc} alt={network || ''} />
                }
              />
            }
          />
        </InputWrapper>
        <InputWrapper error={errors.consent?.message}>
          <Checkbox
            mt={errors.address ? '8' : '0'}
            ml="16"
            label="I confirm this address is mine and valid."
            {...register('consent', {
              required: true,
            })}
          />
        </InputWrapper>
      </form>
    </>
  );
}

const InformationDialog = ({ onClose }: { onClose: () => void }) => {
  return (
    <Dialog onRequestClose={onClose}>
      <DialogHeader description="Since USDT transfers are no longer supported due to regulatory constraints, we’ll fulfil this refund by transferring your funds in USDC.">
        Important information
      </DialogHeader>
      <DialogActions>
        <Button
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href={links.support.usdt_deprecation}
          size="medium"
          type="button"
          variant="secondary"
        >
          Learn more
        </Button>
        <Button size="medium" type="button" variant="primary" onClick={onClose}>
          I understand
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const USDTMessage = () => (
  <>
    <Text size="small" color="neutral-500">
      Due to regulatory constraints, USDT is no longer supported. This refund
      will be issued in USDC.
    </Text>
    <Box as="div" mt="8">
      <TextLink href={links.support.usdt_deprecation} target="_blank">
        <Center inline>
          <Box mr="4">Learn more</Box>
          <Icon size={16} component={<ExternalLinkSvg />} />
        </Center>
      </TextLink>
    </Box>
  </>
);
