import { useGetRefundProposalQuery } from '../../../../graphql/generated';
import { useAppStore } from '../../../../main/store';
import {
  checkGraphqlError,
  currency_disabled_errors,
} from '../../../../services/apollo/utils';

export default function useRefundRequest() {
  const setRefundCurrencyDisabled = useAppStore(
    (state) => state.setRefundCurrencyDisabled
  );
  const { data, loading } = useGetRefundProposalQuery({
    onError: (error) => {
      if (error.graphQLErrors) {
        const isCurrencyDisabledError = checkGraphqlError(
          error.graphQLErrors,
          currency_disabled_errors
        );
        if (isCurrencyDisabledError) {
          setRefundCurrencyDisabled(true);
        }
      }
    },
  });

  const isBillPaymentUSDT =
    data?.refund?.sourceCryptoAmount?.billPaymentCurrency === 'USDT';

  const initialCurrency = data?.refund?.initialAmount?.currency;
  const convertedCurrency = data?.refund?.proposal?.convertedAmount?.currency;

  // Determines if this is a crypto-to-crypto refund by checking:
  // 1. If initial currency is USDT (special case since USDT gets converted to USDC)
  // 2. If initial and converted currencies match (standard crypto-to-crypto internal refund)
  const cryptoToCrypto =
    initialCurrency === 'USDT' || initialCurrency === convertedCurrency;

  const networkData = data?.refund?.proposal?.convertedAmount?.blockchain;

  const network = networkData === 'LN-BTC' ? 'BTC' : networkData;

  const shouldShowUSDTWarning =
    // USDT selected as bill payment and initial currency something like EUR or payment made in USDT and converted to USDC
    (isBillPaymentUSDT && initialCurrency !== convertedCurrency) ||
    // payment made in USDT and converted to USDC
    (initialCurrency === 'USDT' && convertedCurrency !== 'USDT');

  return {
    loading,
    hasProposal: !!data?.refund?.proposal,
    data: {
      id: data?.refund?.id,
      cryptoAmount: data?.refund?.proposal?.convertedAmount?.value,
      shouldShowUSDTWarning,
      cryptoCurrency: data?.refund?.proposal?.convertedAmount?.currency,
      network,
      fiatAmount: cryptoToCrypto ? null : data?.refund?.initialAmount?.value,
      fiatCurrency: cryptoToCrypto
        ? null
        : data?.refund?.initialAmount?.currency,
      exchangeRate: data?.refund?.proposal?.exchangeRate,
      expiresAt: data?.refund?.proposal?.expiresAt,
    },
  };
}
